import styled from '@emotion/styled';

export const MainViewContainer = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;

  h1 {
    font-size: 2rem
  }
`