import styled from '@emotion/styled';

export const App = styled.div`
  background: #ffffff;
  height: 100vh;
  display: flex
`

export const Header = styled.h1`
  color: pink
`