import React from 'react';
import * as s from './App.styles';
import * as Palette from './colors'

// Components
import Sidebar from './components/Sidebar/Sidebar';
import MainView from './components/MainView/MainView'


const App = () => {
  const backgroundImage = 'images/colors-bg.jpg';
  
  const sidebarHeader = {
    fullName: 'Sandip Sorathia',
    shortName: 'SS',
    siteLogo: 'images/logo.png'
  };
  const menuItems = [
    {name: 'Home', to: '/', icon: '/icons/home.svg', subMenuItems: [] },
    {name: 'About', to: '/about', icon: '/icons/about.svg', subMenuItems: [] },
    {name: 'Work', to: '/work', icon: '/icons/services.svg', subMenuItems: [] },
    {name: 'Blog', to: '/blog', icon: '/icons/blog.svg', subMenuItems: [] },
    {name: 'Contact', to: '/contact', icon: '/icons/contacts.svg', subMenuItems: [] }
  ];

  const socialIcons = [
    {name: 'Twitter', to: 'https://twitter.com/sandipsorathia', icon: '/icons/twitter.svg'},
    {name: 'Linked In', to: 'https://www.linkedin.com/in/sandipsorathia/', icon: '/icons/linkedin.svg'}
  ];

  const fonts = {
    header: 'ZCOOL KuaiLe',
    menu: 'Poppins'
  }

  return (
    <s.App>
      <Sidebar
        backgroundImage={backgroundImage}
        sidebarHeader={sidebarHeader}
        menuItems={menuItems}
        socialIcons={socialIcons}
        fonts={fonts}
        colorPalette={Palette.navy}
      />
      <MainView />
    </s.App>
  );
}

export default App;
