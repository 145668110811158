import React from 'react';
import sswindow from '../../../img/ss_window.jpg';
import ss from '../../../img/Waterfall.mp4';

const Home = () => {
  return (
  <div className="hero">
      <div className="color-overlay"></div>
      <h1>Welcome to SandipSorathia.Co.Uk</h1>
      
      <img src={sswindow} alt="Window" />
      <video autoPlay muted loop src={ss} />

      
  </div>
  )
  //return <h1>Welcome to SandipSorathia.Co.Uk</h1>
}

export default Home